import { styled } from 'Theme/stitches.config';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { FetchCartAndNotifyAll } from '../Cart';

import { useState } from 'react';
import GiftCardModel from 'Models/GiftCard/GiftCardModel.interface';
import { API_ROOT_PATH } from 'Shared/Constants/route';
import { Trash } from 'DesignSystem/Icons';
import AnimatedIcon from 'DesignSystem/Icons/ExtenderIcons/AnimatedIcon';
import LoadingCircle from 'DesignComponents/Atoms/Loaders/LoadingCircle';
import { SrOnlyStyle } from 'DesignSystem/Accessibility/Utils';
import KexCartModel from 'Models/Cart/KexCartModel.interface';

const GIFTCARD_REMOVE_URL = API_ROOT_PATH + '/GiftCard/remove';
const DISCOUNT_REMOVE_URL = API_ROOT_PATH + '/cart/removeCoupon';

export type MiniCartSummaryKeyValueItemType =
  | 'amount'
  | 'discount'
  | 'shipping'
  | 'giftCard'
  | 'OrderDiscount'
  | 'total';

export interface BaseMiniCartSummaryKeyValueItem {
  text?: string;
  type: MiniCartSummaryKeyValueItemType;
  discount?: boolean;
}

//Type amount, discount, shipping and total can only be used with a value of type string
export interface StringMiniCartSummaryKeyValueItem
  extends BaseMiniCartSummaryKeyValueItem {
  type: Exclude<MiniCartSummaryKeyValueItemType, 'giftCard'>;
  value?: any;
}

//Type giftCard can only be used with a value of type GiftCardModel[]
export interface GiftCardMiniCartSummaryKeyValueItem
  extends BaseMiniCartSummaryKeyValueItem {
  type: 'giftCard';
  value?: GiftCardModel[];
}

export type MiniCartSummaryKeyValueItem =
  | StringMiniCartSummaryKeyValueItem
  | GiftCardMiniCartSummaryKeyValueItem;

type MiniCartSummaryProps = {
  cart?: KexCartModel;
  readOnly: boolean;
};

const MiniCartSummary = ({ cart, readOnly }: MiniCartSummaryProps) => {
  const { languageRoute } = useAppSettingsData();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDiscount, setIsLoadingDiscount] = useState(false);
  const [removeCode, setRemoveCode] = useState('');
  const isCartEmpty = cart?.lineItems.length === 0;

  const {
    cartLabels: {
      cartSummary,
      total,
      shipping,
      freeShipping,
      amount,
      confirmRemoveCoupon,
    },
    productLabels: { remove: removeLabel },
    giftCardLabels: { confirmRemoveGiftCard, removingGiftCard, giftCard },
  } = useTranslationData();

  const RemoveGiftCardFromCart = async (code: string) => {
    if (confirm(confirmRemoveGiftCard) === false) return;
    setIsLoading(true);
    setRemoveCode(code);
    const formData = new FormData();
    formData.append('code', code);

    try {
      const res = await fetch(GIFTCARD_REMOVE_URL, {
        method: 'POST',
        body: formData,
      });

      if (res.ok) {
        await FetchCartAndNotifyAll(languageRoute);
      }
    } catch (error) {
      // Handle error
    }

    setIsLoading(false);
    setRemoveCode('');
  };

  const RemoveDiscountFromCart = async (code: string) => {
    if (confirm(confirmRemoveCoupon) === false) return;
    setIsLoadingDiscount(true);
    const formData = new FormData();
    formData.append('code', code);

    try {
      const res = await fetch(DISCOUNT_REMOVE_URL, {
        method: 'POST',
        body: formData,
      });

      if (res.ok) {
        await FetchCartAndNotifyAll(languageRoute);
      }
    } catch (error) {
      // Handle error
    }

    setIsLoadingDiscount(false);
  };

  const remove = (code: string, type: 'giftCard' | 'discount') => {
    if (readOnly) {
      return <></>;
    }

    return (
      <RemoveButton
        onClick={() => {
          type === 'discount'
            ? RemoveDiscountFromCart(code)
            : RemoveGiftCardFromCart(code);
        }}
      >
        <Trash size="s" color={'onInteractiveSecondary'} title={removeLabel} />
      </RemoveButton>
    );
  };

  if (!cart || cart.numberOfItems === 0) return null;

  const summaryList: MiniCartSummaryKeyValueItem[] = [
    {
      text: amount,
      type: 'amount',
      value: cart?.lineItemsTotalPrice,
    },
    {
      text: shipping,
      type: 'shipping',
      value: cart?.shippingTotalPrice,
    },
    {
      text: cart?.appliedDiscount?.rewardName,
      type: 'discount',
      value: cart?.appliedDiscount?.totalDiscount,
      discount: !!cart?.appliedDiscount,
    },
    {
      text: giftCard,
      type: 'giftCard',
      value: cart?.giftCards,
    },
    {
      text: total,
      type: 'total',
      value: cart?.totalPriceLeftToPay,
    },
  ];

  if (!summaryList || summaryList.length <= 0 || isCartEmpty) return null;

  const filteredSummaryList = summaryList.filter((listItem) => {
    if (listItem.text === undefined || listItem.value === undefined) {
      return false;
    }
    return true;
  });

  if (!filteredSummaryList || filteredSummaryList.length <= 0) return null;

  return (
    <>
      <LoadingInfo aria-live="assertive" aria-atomic="true">
        {isLoading ? removingGiftCard : ''}
      </LoadingInfo>
      <SummaryTable>
        <caption>{cartSummary}</caption>
        <tbody>
          {filteredSummaryList.map((listItem, index) => {
            if (listItem.type === 'giftCard') {
              return listItem.value?.map((giftCard, giftCardIndex) => (
                <SummaryRow key={giftCardIndex}>
                  <SummaryCellHeader scope="row">
                    {giftCard.displayName}
                    {remove(giftCard.code, 'giftCard')}
                    {isLoading && giftCard.code === removeCode && (
                      <AnimatedIcon animation={'spinAnimation'}>
                        <LoadingCircle />
                      </AnimatedIcon>
                    )}
                  </SummaryCellHeader>
                  {!giftCard.amountUsed.isZero && (
                    <SummaryCell>
                      {'-' + giftCard.amountUsed.priceAsString}{' '}
                      <Currency>
                        {giftCard.availableAmount.currencySymbol}
                      </Currency>
                    </SummaryCell>
                  )}
                </SummaryRow>
              ));
            }

            if (listItem.type === 'discount') {
              return (
                <SummaryRow key={index}>
                  <SummaryCellHeader scope="row">
                    {listItem.text}
                    {remove(cart.appliedDiscount.couponCode, 'discount')}
                    {isLoadingDiscount && (
                      <AnimatedIcon animation={'spinAnimation'}>
                        <LoadingCircle />
                      </AnimatedIcon>
                    )}
                  </SummaryCellHeader>
                  {!listItem.value.isZero && (
                    <SummaryCell>
                      {'-' + listItem.value.priceAsString}{' '}
                      <Currency>{listItem.value.currencySymbol}</Currency>
                    </SummaryCell>
                  )}
                </SummaryRow>
              );
            }

            if (listItem.type === 'shipping') {
              return (
                <SummaryRow key={index}>
                  <SummaryCellHeader scope="row">
                    {listItem.text}
                  </SummaryCellHeader>
                  <SummaryCell
                    isLarge={filteredSummaryList.length - 1 === index}
                  >
                    {listItem?.value?.price > 0 ? (
                      <>
                        {listItem.value.priceAsString}{' '}
                        <Currency>{listItem.value.currencySymbol}</Currency>
                      </>
                    ) : cart?.hasReachedFreeFreight ? (
                      freeShipping
                    ) : null}
                  </SummaryCell>
                </SummaryRow>
              );
            }

            return (
              <SummaryRow key={index}>
                <SummaryCellHeader scope="row">
                  {listItem.text}
                </SummaryCellHeader>
                <SummaryCell isLarge={filteredSummaryList.length - 1 === index}>
                  <>
                    {listItem.value.priceAsString}{' '}
                    <Currency>{listItem.value.currencySymbol}</Currency>
                  </>
                </SummaryCell>
              </SummaryRow>
            );
          })}
        </tbody>
      </SummaryTable>
    </>
  );
};

export default MiniCartSummary;

const SummaryRow = styled('tr', {
  color: '$onSurface',
  display: 'flex',
  justifyContent: 'space-between',
  mb: '$s50',
  '&:last-child': {
    mb: 0,
  },
});

const SummaryCellHeader = styled('th', {
  display: 'flex',
  alignItems: 'start',
  fontWeight: '$fontWeightRegular',
  fontSize: '$fontSize75',
  textAlign: 'left',
});

const SummaryCell = styled('td', {
  fontFamily: 'fontBold',
  fontSize: '$fontSize200',
  whiteSpace: 'nowrap',
  paddingLeft: '$s400',
  variants: {
    isLarge: {
      true: {
        fontSize: '$fontSize500',
      },
    },
  },
});

const SummaryTable = styled('table', {
  w: '100%',
  p: '$s50',
  mb: '$s75',
  '& tr:last-child :where(td, th)': {
    pt: '20px',
  },
  caption: {
    ...SrOnlyStyle,
  },
});

const RemoveButton = styled('button', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
});

const LoadingInfo = styled('div', {
  ...SrOnlyStyle,
});

const Currency = styled('span', {
  fontSize: '$fontSize75',
});
